import { simNaoEnumToBoolean } from 'components/form'
import { CidadaoFciInput, InformacoesSocioEconomicas, InformacoesSocioEconomicasInput } from 'graphql/types.generated'
import { tipoSanguineo } from 'types/enums'
import { booleanToSimNaoEnum } from 'view/cadastro-imovel/utils-cadastroImovel'

import {
  CidadaoFci,
  CidadaoFciFormModel,
  EstadoCivilRecord,
  InformacoesSocioEconomicasFormModel,
} from './model-cidadaoFci'

export function convertToCidadaoFciInput(model: CidadaoFciFormModel): CidadaoFciInput {
  const { dadosPessoais, informacoesSocioEconomicas } = model

  return {
    id: model.id,
    nome: dadosPessoais.nome,
    nomeSocial: dadosPessoais.nomeSocial,
    sexo: dadosPessoais.sexo,
    dataNascimento: dadosPessoais.dataNascimento,
    cpf: dadosPessoais.cpf,
    cns: dadosPessoais.cns,
    nisPisPasep: dadosPessoais.nisPisPasep,
    racaCor: dadosPessoais.racaCor.value,
    etniaId: dadosPessoais.etnia?.id,
    tipoSanguineo: dadosPessoais.tipoSanguineo?.identificador,
    estadoCivil: EstadoCivilRecord[dadosPessoais.estadoCivil?.id],
    nacionalidade: dadosPessoais.nacionalidadeCidadao.nacionalidade,
    municipioNascimento: dadosPessoais.nacionalidadeCidadao.municipioNascimento?.id,
    dataEntradaPais: dadosPessoais.nacionalidadeCidadao.dataEntradaPais,
    paisNascimento: dadosPessoais.nacionalidadeCidadao.paisNascimento?.id,
    dataNaturalizacao: dadosPessoais.nacionalidadeCidadao.dataNaturalizacao,
    portariaNaturalizacao: dadosPessoais.nacionalidadeCidadao.portariaNaturalizacao,
    nomePai: dadosPessoais.nomePai,
    nomeMae: dadosPessoais.nomeMae,
    desconheceNomePai: dadosPessoais.desconheceNomePai,
    desconheceNomeMae: dadosPessoais.desconheceNomeMae,
    vinculacaoCidadaoTerritorio: {
      cnes: '700401374599450',
      ine: '1010010001',
      cbo2002: '515105',
      cns: '700401374599450',
    },
    informacoesSocioEconomicas: convertInformacoesSocioEconomicasToInput(informacoesSocioEconomicas),
  }
}

export function convertToCidadaoFciFormModel(cidadao: CidadaoFci): CidadaoFciFormModel {
  return {
    id: cidadao.id,
    dadosPessoais: {
      nome: cidadao.nome,
      nomeSocial: cidadao?.nomeSocial,
      sexo: cidadao.sexo,
      dataNascimento: cidadao.dataNascimento,
      racaCor: {
        id: cidadao.racaCor.id,
        nome: cidadao.racaCor.nome,
        value: cidadao.racaCor.racaCorDbEnum,
      },
      etnia: cidadao?.etnia,
      tipoSanguineo: {
        identificador: cidadao?.tipoSanguineo,
        nome: tipoSanguineo[cidadao?.tipoSanguineo],
      },
      cpf: cidadao?.cpf,
      cns: cidadao?.cns,
      nisPisPasep: cidadao?.nisPisPasep,
      nomeMae: cidadao?.nomeMae,
      nomePai: cidadao?.nomePai,
      desconheceNomeMae: cidadao?.desconheceNomeMae,
      desconheceNomePai: cidadao?.desconheceNomePai,
      estadoCivil: cidadao?.estadoCivil,
      nacionalidadeCidadao: {
        nacionalidade: cidadao.nacionalidade.nacionalidadeDbEnum,
        municipioNascimento: {
          id: cidadao?.localidadeNascimento?.id,
          nome: cidadao?.localidadeNascimento?.nome,
          uf: cidadao?.localidadeNascimento?.uf,
          ibge: cidadao?.localidadeNascimento?.ibge,
        },
        dataEntradaPais: cidadao?.dataEntradaBrasil,
        paisNascimento: {
          id: cidadao?.paisNascimento?.id,
          nome: cidadao?.paisNascimento?.nome,
        },
        dataNaturalizacao: cidadao?.dataNaturalizacao,
        portariaNaturalizacao: cidadao?.portariaNaturalizacao,
      },
    },
    informacoesSocioEconomicas: convertInformacoesSocioEconomicasToFormModel(cidadao?.informacoesSocioEconomicas),
  }
}

export const convertInformacoesSocioEconomicasToInput = (
  form: InformacoesSocioEconomicasFormModel
): InformacoesSocioEconomicasInput => {
  const hasInformacoesSocioEconomicas =
    !!form?.alimentosAcabaramAntesTerDinheiroComprarMais || !!form?.comeuAlgunsAlimentosDinheiroAcabou

  return hasInformacoesSocioEconomicas
    ? {
        statusAlimentosAcabaramAntesTerDinheiroComprarMais:
          simNaoEnumToBoolean[form.alimentosAcabaramAntesTerDinheiroComprarMais],
        statusComeuAlgunsAlimentosQueTinhaDinheiroAcabou: simNaoEnumToBoolean[form.comeuAlgunsAlimentosDinheiroAcabou],
      }
    : null
}

export const convertInformacoesSocioEconomicasToFormModel = (
  informacoesSocioEconomicas: InformacoesSocioEconomicas
): InformacoesSocioEconomicasFormModel => {
  return (
    informacoesSocioEconomicas && {
      alimentosAcabaramAntesTerDinheiroComprarMais: booleanToSimNaoEnum(
        informacoesSocioEconomicas?.statusAlimentosAcabaramAntesTerDinheiroComprarMais
      ),
      comeuAlgunsAlimentosDinheiroAcabou: booleanToSimNaoEnum(
        informacoesSocioEconomicas?.statusComeuAlgunsAlimentosQueTinhaDinheiroAcabou
      ),
    }
  )
}
