import { RacaCorSelectModel } from 'components/form/field/select/RacaCorSelectField'
import createDecorator, { Calculation } from 'final-form-calculate'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'

import { CidadaoFciFormModel } from './model-cidadaoFci'

const createEtniaCalculator = (meta: MetaRoot<CidadaoFciFormModel>): Calculation[] => [
  {
    field: meta?.dadosPessoais?.racaCor.absolutePath(),
    updates: {
      [meta?.dadosPessoais?.etnia?.absolutePath()]: (racaCor: RacaCorSelectModel, allValues: CidadaoFciFormModel) =>
        racaCor?.value !== RacaCorDbEnum.INDIGENA ? null : allValues?.dadosPessoais?.etnia,
    },
  },
]

const createNomeGenitoresCalculator = (meta: MetaRoot<CidadaoFciFormModel>): Calculation[] => [
  {
    field: meta?.dadosPessoais?.desconheceNomeMae.absolutePath(),
    updates: {
      [meta?.dadosPessoais?.nomeMae.absolutePath()]: (desconheceNomeMae: boolean, allValues: CidadaoFciFormModel) =>
        desconheceNomeMae ? null : allValues?.dadosPessoais?.nomeMae,
    },
  },
  {
    field: meta?.dadosPessoais?.desconheceNomePai.absolutePath(),
    updates: {
      [meta?.dadosPessoais?.nomePai.absolutePath()]: (desconheceNomePai: boolean, allValues: CidadaoFciFormModel) =>
        desconheceNomePai ? null : allValues?.dadosPessoais?.nomePai,
    },
  },
]

const createNacionalidadeCalculator = (meta: MetaRoot<CidadaoFciFormModel>): Calculation[] => [
  {
    field: meta?.dadosPessoais?.nacionalidadeCidadao?.nacionalidade.absolutePath(),
    updates: {
      [meta?.dadosPessoais?.nacionalidadeCidadao?.municipioNascimento.absolutePath()]: (
        nacionalidadeEnum: NacionalidadeEnum,
        allValues: CidadaoFciFormModel
      ) =>
        nacionalidadeEnum !== NacionalidadeEnum.BRASILEIRA
          ? null
          : allValues?.dadosPessoais?.nacionalidadeCidadao?.municipioNascimento,

      [meta?.dadosPessoais?.nacionalidadeCidadao?.portariaNaturalizacao.absolutePath()]: (
        nacionalidadeEnum: NacionalidadeEnum,
        allValues: CidadaoFciFormModel
      ) =>
        nacionalidadeEnum !== NacionalidadeEnum.NATURALIZADA
          ? null
          : allValues?.dadosPessoais?.nacionalidadeCidadao?.portariaNaturalizacao,
      [meta?.dadosPessoais?.nacionalidadeCidadao?.dataNaturalizacao.absolutePath()]: (
        nacionalidadeEnum: NacionalidadeEnum,
        allValues: CidadaoFciFormModel
      ) =>
        nacionalidadeEnum !== NacionalidadeEnum.NATURALIZADA
          ? null
          : allValues?.dadosPessoais?.nacionalidadeCidadao?.dataNaturalizacao,

      [meta?.dadosPessoais?.nacionalidadeCidadao?.paisNascimento.absolutePath()]: (
        nacionalidadeEnum: NacionalidadeEnum,
        allValues: CidadaoFciFormModel
      ) =>
        nacionalidadeEnum !== NacionalidadeEnum.ESTRANGEIRA
          ? null
          : allValues?.dadosPessoais?.nacionalidadeCidadao?.paisNascimento,
      [meta?.dadosPessoais?.nacionalidadeCidadao?.dataEntradaPais.absolutePath()]: (
        nacionalidadeEnum: NacionalidadeEnum,
        allValues: CidadaoFciFormModel
      ) =>
        nacionalidadeEnum !== NacionalidadeEnum.ESTRANGEIRA
          ? null
          : allValues?.dadosPessoais?.nacionalidadeCidadao?.dataEntradaPais,
    },
  },
]

export const createCidadaoFciCalculator = (meta: MetaRoot<CidadaoFciFormModel>) =>
  createDecorator(
    ...createEtniaCalculator(meta),
    ...createNomeGenitoresCalculator(meta),
    ...createNacionalidadeCalculator(meta)
  )
