import { MunicipioSelectModel, SimNaoEnum } from 'components/form'
import { EstadoCivilSelectModel } from 'components/form/field/select/EstadoCivilSelectField'
import { EtniaSelectFieldModel } from 'components/form/field/select/EtniaSelectField'
import { PaisSelectFieldModel } from 'components/form/field/select/PaisSelectField'
import { RacaCorSelectModel } from 'components/form/field/select/RacaCorSelectField'
import { TipoSanguineoSelectModel } from 'components/form/field/select/TipoSanguineoSelectField'
import { CidadaoFciAtualizacaoQuery, EstadoCivilEnum, NacionalidadeEnum, SexoEnum } from 'graphql/types.generated'

export interface DadosPessoaisFormModel {
  cpf?: string
  cns?: string
  nisPisPasep?: string
  nome: string
  nomeSocial?: string
  dataNascimento: string
  sexo: SexoEnum
  estadoCivil?: EstadoCivilSelectModel
  tipoSanguineo?: TipoSanguineoSelectModel
  racaCor: RacaCorSelectModel
  etnia?: EtniaSelectFieldModel
  nomeMae?: string
  nomePai?: string
  desconheceNomeMae: boolean
  desconheceNomePai: boolean
  nacionalidadeCidadao: NacionalidadeFormModel
}

export interface InformacoesSocioEconomicasFormModel {
  openedAccordion?: (string | number)[]
  alimentosAcabaramAntesTerDinheiroComprarMais: SimNaoEnum
  comeuAlgunsAlimentosDinheiroAcabou: SimNaoEnum
}

export interface CidadaoFciFormModel {
  id?: ID
  dadosPessoais: DadosPessoaisFormModel
  informacoesSocioEconomicas: InformacoesSocioEconomicasFormModel
}

export interface NacionalidadeFormModel {
  nacionalidade: NacionalidadeEnum
  municipioNascimento?: MunicipioSelectModel
  portariaNaturalizacao?: string
  dataNaturalizacao?: LocalDate
  paisNascimento?: PaisSelectFieldModel
  dataEntradaPais?: LocalDate
}

export type CidadaoFci = CidadaoFciAtualizacaoQuery['cidadao']

export const EstadoCivilRecord: Record<ID, EstadoCivilEnum> = {
  '1': EstadoCivilEnum.SOLTEIRO,
  '2': EstadoCivilEnum.CASADO,
  '3': EstadoCivilEnum.VIUVO,
  '4': EstadoCivilEnum.SEPARADO_JUDICIALMENTE,
  '5': EstadoCivilEnum.UNIAO_CONSENSUAL,
}
