import { EnderecoFieldGroupModel } from 'components/form'
import { EnderecoIndigenaFieldGroupModel } from 'components/form/field/enderecoindigena/model-enderecoIndigena'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { LotacaoResponsavelFormModel } from 'components/lotacao-responsavel/model-lotacaoResponsavel'
import { isBefore, parseISO, subMonths } from 'date-fns'
import { TipoImovelEnum, TipoLocalizacaoEnum } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { isObjectDeepEmpty } from 'util/object'
import {
  afterEqualTo,
  alfaNumerico,
  arrayMaxLength,
  beforeEqualTo,
  beforeEqualToday,
  cep,
  cns,
  createValidator,
  empty,
  ErrorObject,
  length,
  maxLength,
  microarea,
  minLength,
  nome,
  numeroEndereco,
  range,
  required,
  telefone,
} from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import {
  AnimaisImovelFormModel,
  CadastrarCidadaoFamiliaFormModel,
  CadastrarEditarFamiliaFormModel,
  CondicoesMoradiaFormModel,
  FamiliaFormModel,
  ImovelFormModel,
  InstituicaoPermanenciaFormModel,
  RegistroObitoCidadaoFormModel,
  ResponsavelTecnicoFormModel,
  tipoEnderecoIndigena,
} from './model-cadastroImovel'
import {
  isTipoImovelDomicilio,
  isTipoImovelResidencial,
  isTipoImovelResidencialExcetoDomicilio,
} from './utils-cadastroImovel'

export const validateCondicoesDeMoradia = (tipoImovel: TipoImovelEnum) =>
  createValidator<CondicoesMoradiaFormModel>(
    {
      numeroComodos: [range(1, 99)],
    },
    (values: CondicoesMoradiaFormModel, errors: ErrorObject<CondicoesMoradiaFormModel>) => {
      if (tipoImovel) {
        if (!isTipoImovelDomicilio(tipoImovel)) {
          errors.tipoMaterialParede = empty(values?.tipoMaterialParede)
          errors.tipoPosseTerra = empty(values?.tipoPosseTerra)
          errors.tipoSituacaoMoradia = empty(values?.tipoSituacaoMoradia)
        } else {
          errors.tipoSituacaoMoradia = required(values?.tipoSituacaoMoradia)
        }
      }

      if (values?.tipoLocalizacao && values?.tipoLocalizacao === TipoLocalizacaoEnum.URBANA) {
        errors.tipoPosseTerra = empty(values?.tipoPosseTerra)
      }

      if (isTipoImovelResidencialExcetoDomicilio(tipoImovel) || isTipoImovelDomicilio(tipoImovel)) {
        errors.tipoLocalizacao = required(values?.tipoLocalizacao)
      }

      return errors
    }
  )

export const validateAnimaisDomicilioInDomicilio = createValidator<AnimaisImovelFormModel>(
  {},
  (values: AnimaisImovelFormModel, errors: ErrorObject<AnimaisImovelFormModel>) => {
    if (values?.quantidadeAnimais && values?.animais?.length > values?.quantidadeAnimais) {
      errors.quantidadeAnimais = 'Não pode ser menor que a soma dos tipos de animal selecionados.'
    }

    if (isEmpty(values?.animais) && !isUndefinedOrNull(values?.quantidadeAnimais)) {
      errors.quantidadeAnimais = empty(values?.quantidadeAnimais)
    }

    return errors
  }
)

export const validateInstituicaoPermanencia = (tipoImovel: TipoImovelEnum) =>
  createValidator<InstituicaoPermanenciaFormModel>(
    {
      nomeInstituicaoPermanencia: [maxLength(100)],
    },
    (value: InstituicaoPermanenciaFormModel, errors: ErrorObject<InstituicaoPermanenciaFormModel>) => {
      if (!isTipoImovelResidencialExcetoDomicilio(tipoImovel)) {
        errors.nomeInstituicaoPermanencia = empty(value?.nomeInstituicaoPermanencia)
        errors.possuiOutrosProfissionaisVinculados = empty(value?.possuiOutrosProfissionaisVinculados)
        errors.responsavelTecnico = !isObjectDeepEmpty(value?.responsavelTecnico)
          ? empty(errors.responsavelTecnico)
          : undefined
      } else {
        errors.responsavelTecnico = validateResponsavelTecnico()(value?.responsavelTecnico)
      }

      return errors
    }
  )

export const validateResponsavelTecnico = () =>
  createValidator<ResponsavelTecnicoFormModel>({
    contato: [telefone],
    nome: [required, nome(3), maxLength(70)],
    cns: [cns],
    cargo: [maxLength(100)],
  })

export const validateEnderecoCadastroImovel = () => {
  return createValidator<EnderecoFieldGroupModel>(
    {
      bairro: [required],
      cep: [required, cep],
      logradouro: [required],
      municipio: [required],
      tipoLogradouro: [required],
      uf: [required],
      numero: [maxLength(7), required],
      complemento: [maxLength(30)],
      pontoReferencia: [maxLength(40)],
    },
    (values: EnderecoFieldGroupModel, errors: ErrorObject<EnderecoFieldGroupModel>) => {
      if (values?.bairro) {
        errors.bairro = maxLength(72)(values.bairro.nome)
      }
      if (values?.bairro && !errors.bairro) {
        errors.bairro = minLength(1, true)(values.bairro.nome)
      }
      if (values?.logradouro) {
        errors.logradouro = maxLength(72)(values.logradouro.nome)
      }
      if (values?.logradouro && !errors.logradouro) {
        errors.logradouro = minLength(1, true)(values.logradouro.nome)
      }
      if (values?.numero && !errors.numero) {
        errors.numero = numeroEndereco(values?.semNumero)(values.numero)
      }

      return errors
    }
  )
}

const validateEnderecIndigenaCadastroImovel = () =>
  createValidator<EnderecoIndigenaFieldGroupModel>(
    {
      dsei: [required],
      poloBase: [required],
      aldeia: [required],
      municipio: [required],
      uf: [required],
      numero: [maxLength(7), required],
    },
    (
      enderecoIndigenaValues: EnderecoIndigenaFieldGroupModel,
      errors: ErrorObject<EnderecoIndigenaFieldGroupModel>
    ): ErrorObject<EnderecoIndigenaFieldGroupModel> => {
      if (enderecoIndigenaValues?.numero && !errors.numero) {
        errors.numero = numeroEndereco()(enderecoIndigenaValues.numero)
      }

      return errors
    }
  )

export const createFamiliaValidator = (
  isCidadaoPresenteOutrasFamilias: (cidadaoId: ID) => boolean,
  dataColeta: string
) =>
  createValidator<CadastrarEditarFamiliaFormModel>(
    {
      numeroProntuarioFamiliar: [maxLength(30), alfaNumerico],
      responsavel: [required],
      numeroMembros: [range(1, 99)],
    },
    (values: CadastrarEditarFamiliaFormModel, errors: ErrorObject<CadastrarEditarFamiliaFormModel>) => {
      if (values?.responsavel?.id && isCidadaoPresenteOutrasFamilias(values?.responsavel?.id)) {
        errors.responsavel = 'O responsável já está associado a outra família neste mesmo imóvel.'
      }

      if (values?.resideDesdeMonthYear) {
        const { year, month } = values?.resideDesdeMonthYear
        const dataMinimaColeta = parseISO('1500-01-01')
        const resideDesdeDate = new Date(year, month, 1)

        if (isBefore(resideDesdeDate, dataMinimaColeta)) {
          errors.resideDesdeMonthYear = 'Deve ser igual ou posterior à 01/01/1500.'
        }

        if (isBefore(parseISO(dataColeta), resideDesdeDate)) {
          errors.resideDesdeMonthYear = 'Deve ser igual ou anterior à Data de coleta.'
        }

        const errorBeforeEqualToday = beforeEqualToday(resideDesdeDate.toISOString())

        if (errorBeforeEqualToday) {
          errors.resideDesdeMonthYear = errorBeforeEqualToday
        }
      }

      return errors
    }
  )

export const createCadastrarCidadaoFamiliaValidator = (isCidadaoPresenteOutrasFamilias: (cidadaoId: ID) => boolean) =>
  createValidator<CadastrarCidadaoFamiliaFormModel>(
    {},
    (values: CadastrarCidadaoFamiliaFormModel, errors: ErrorObject<CadastrarCidadaoFamiliaFormModel>) => {
      if (values?.cidadao?.id && isCidadaoPresenteOutrasFamilias(values?.cidadao.id)) {
        errors.cidadao = 'O Cidadão já está associado a outra família neste mesmo imóvel.'
      }
      return errors
    }
  )

export const validateImovelForm = (now: Date) =>
  createValidator<ImovelFormModel>(
    {
      responsabilidade: [required],
      tipoEndereco: [required],
      microarea: [microarea],
      telefoneContato: [telefone],
      telefoneResidencial: [telefone],
      dataColeta: [required, beforeEqualToday],
      numeroMoradores: [range(1, 9999)],
      tipoImovel: [required],
      familias: [arrayMaxLength(99)],
    },
    (values: ImovelFormModel, errors: ErrorObject<ImovelFormModel>) => {
      if (!values.microarea && !(values.statusForaArea || values.stMicroareaPoloBase)) {
        errors.microarea = 'Preenchimento obrigatório.'
      }

      if (!!values?.familias?.isNotEmpty() && values?.tipoImovel !== TipoImovelEnum.DOMICILIO) {
        errors.tipoImovel = 'Tipo de imóvel deve ser domicílio.'
      }

      if (values.dataColeta && isBefore(parseISO(values.dataColeta), subMonths(now, 12))) {
        errors.dataColeta = 'Deve ser no máximo 12 meses anterior à data de hoje.'
      }

      errors.condicoesMoradia = validateCondicoesDeMoradia(values?.tipoImovel)(values?.condicoesMoradia)

      errors.animaisNoDomicilio = isTipoImovelDomicilio(values?.tipoImovel)
        ? validateAnimaisDomicilioInDomicilio(values?.animaisNoDomicilio)
        : undefined

      if (!isTipoImovelResidencial(values?.tipoImovel)) {
        errors.numeroMoradores = empty(values?.numeroMoradores)
      }

      errors.instituicaoPermanencia = validateInstituicaoPermanencia(values?.tipoImovel)(values?.instituicaoPermanencia)

      if (tipoEnderecoIndigena.includes(values?.tipoEndereco)) {
        errors.endereco = empty(values?.endereco)
        errors.enderecoIndigena = validateEnderecIndigenaCadastroImovel()(values?.enderecoIndigena)
      } else {
        errors.endereco = validateEnderecoCadastroImovel()(values?.endereco)
        errors.enderecoIndigena = empty(values?.enderecoIndigena)
      }

      if (values.numeroMoradores && values.familias) {
        const totalNumeroMembros = values.familias.reduce((total, familia) => {
          return familia.isMudouSe ? total : total + (familia.numeroMembros || 1)
        }, 0)

        if (totalNumeroMembros > values.numeroMoradores) {
          errors.numeroMoradores = 'Não pode ser menor que o total de membros informados nas famílias'
        }
      }

      if (values.responsabilidade) {
        errors.responsabilidade = validateResponsabilidade()(values?.responsabilidade)
      }

      return errors
    }
  )

export const createRegistroObitoCidadaoValidator = (
  dataNascimento: LocalDate,
  dataEntradaBrasil: LocalDate,
  dataNaturalizacao: LocalDate,
  dataColeta: LocalDate
) =>
  createValidator<RegistroObitoCidadaoFormModel>(
    {
      dataObito: [required, beforeEqualToday],
      numeroDeclaracaoObito: [length(9)],
    },
    (values: RegistroObitoCidadaoFormModel, errors: ErrorObject<RegistroObitoCidadaoFormModel>) => {
      if (afterEqualTo(values?.dataObito, dataNascimento, 'data de nascimento')) {
        errors.dataObito = 'Deve ser igual ou posterior a data de nascimento do cidadão.'
      } else if (dataEntradaBrasil && afterEqualTo(values?.dataObito, dataEntradaBrasil, 'data de entrada no brasil')) {
        errors.dataObito = 'Deve ser igual ou posterior a data de entrada do cidadão no Brasil.'
      } else if (dataNaturalizacao && afterEqualTo(values?.dataObito, dataNaturalizacao, 'data de naturalização')) {
        errors.dataObito = 'Deve ser igual ou posterior a data de naturalização do cidadão.'
      } else if (dataColeta && beforeEqualTo(values?.dataObito, dataColeta, 'data coleta')) {
        errors.dataObito = 'Deve ser igual ou anterior a data de coleta'
      }
      return errors
    }
  )

export const validateFamilias = (familias: Array<FamiliaFormModel>) => {
  const familiaInvalida = familias.find((familia) => {
    if (!familia.responsavelId) {
      return true
    }

    const responsavel = familia.cidadaos.find((cidadao) => cidadao.id === familia.responsavelId)

    return responsavel && (responsavel.mudouSe || responsavel.registroObito)
  })

  if (familiaInvalida) {
    if (!familiaInvalida.responsavelId) {
      return 'Não é possível salvar famílias sem um responsável.'
    }

    const responsavel = familiaInvalida.cidadaos.find((cidadao) => cidadao.id === familiaInvalida.responsavelId)

    if (responsavel.mudouSe) {
      return 'Não é possível salvar uma família cujo responsável se mudou'
    }

    if (responsavel.registroObito) {
      return 'Não é possível salvar uma família cujo responsável foi declarado com óbito'
    }
  }

  return null
}

export const validateResponsabilidade = () => {
  return createValidator<LotacaoResponsavelFormModel>(
    {
      lotacao: [required],
    },
    (values: LotacaoResponsavelFormModel, errors: ErrorObject<LotacaoResponsavelFormModel>) => {
      if (!!values?.lotacao) {
        errors.lotacao = validateLotacao()(values?.lotacao)
      }
      return errors
    }
  )
}

export const validateLotacao = () => {
  return createValidator<LotacaoAndEstagioSelectFieldModel>(
    {
      profissional: [required],
      unidadeSaude: [required],
      cbo: [required],
    },
    (values: LotacaoAndEstagioSelectFieldModel, errors: ErrorObject<LotacaoAndEstagioSelectFieldModel>) => {
      if (
        values?.cbo &&
        (!values.cbo?.actions.cadastroDomiciliar.enabled || !values.cbo?.actions.cadastroIndividual.enabled)
      ) {
        errors.cbo = 'O responsável informado possui um CBO inválido para um imóvel.'
      }
      return errors
    }
  )
}
