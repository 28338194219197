import { isAfter, isBefore } from 'date-fns'
import { maxBy } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'

import { MedicaoAntropometricaPreNatal } from './model-medicoesPreNatal'
import { renderMedicaoPreNatal } from './render-medicoesPreNatal'

export function getPesoAndAlturaPreGestacional(
  dum: Date,
  medicoes: MedicaoAntropometricaPreNatal[]
): MedicaoAntropometricaPreNatal {
  if (isUndefinedOrNull(dum) || isUndefinedOrNull(medicoes) || medicoes.isEmpty()) return null

  const medicoesAnterioresDum = medicoes.filter(
    (medicao) => isBefore(medicao.dataMedicao, dum) && medicao.altura !== null && medicao.peso !== null
  )
  const medicaoMaisRecenteAnteriorDum = maxBy(medicoesAnterioresDum, (medicao) => medicao.dataMedicao)

  if (isUndefinedOrNull(medicaoMaisRecenteAnteriorDum)) return null

  return medicaoMaisRecenteAnteriorDum
}

export function getMedicoesPesoPosDum(
  dum: Date,
  medicoesPeso: MedicaoAntropometricaPreNatal[]
): MedicaoAntropometricaPreNatal[] {
  if (isUndefinedOrNull(dum) || isUndefinedOrNull(medicoesPeso) || medicoesPeso.isEmpty()) return null

  const medicoesPesoAposDum = medicoesPeso.filter(
    (medicao) => isAfter(medicao.dataMedicao, dum) && medicao.peso !== null
  )

  return medicoesPesoAposDum
}

export function computeGanhoPesoGestacional(pesoPreGestacional: number, pesoConsulta: number): number {
  if (isUndefinedOrNull(pesoPreGestacional) || isUndefinedOrNull(pesoConsulta)) return null
  return pesoConsulta - pesoPreGestacional
}

export function renderGanhoPeso(pesoPreGestacional: number, pesoConsulta: number): String {
  var ganhoPeso = computeGanhoPesoGestacional(pesoPreGestacional, pesoConsulta)
  return `${ganhoPeso < -0.005 ? '-' : ''}${renderMedicaoPreNatal(Math.abs(ganhoPeso))}`
}

export function getPesoAtendimentoAtual(medicoesPeso: MedicaoAntropometricaPreNatal[]): number {
  return medicoesPeso.find((medicao) => medicao.tipoMedicao === 'atual')?.peso
}
